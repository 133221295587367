// グループ会社・拠点一覧
//
interface Base {
  name: string
  thumbnail: string
  thumbnailClass: string
  link: string
  service: string
  zipcode: string
  location: string
  tel: string
  fax?: string
  tbody?: {
    th: string
    td: {
      zipcode: string
      location: string
      mapLink: string
      phoneNumber: string
      phoneNumber2?: string
    }
  }[]
}
const Base1: Base = {
  name: '株式会社キャスティングロード',
  thumbnail: '/company/img/base01.png',
  thumbnailClass: '',
  link: 'https://www.cr2.co.jp/',
  service:
    '労働者派遣事業、有料職業紹介事業（ハイキャリアエージェントサービス含む）、採用支援事業、BPO事業',
  zipcode: '〒160-0022',
  location: '東京都新宿区新宿3-1-24 京王新宿三丁目ビル7階',
  tel: '03-6384-0520',
  fax: '03-5312-5194',
  tbody: [
    {
      th: '本社',
      td: {
        zipcode: '〒160-0022',
        location: '東京都新宿区新宿3-1-24 京王新宿三丁目ビル7F',
        mapLink: 'https://goo.gl/maps/vGXhpg34FeE2',
        phoneNumber: '03-6384-0520',
      },
    },
    {
      th: '池袋支店',
      td: {
        zipcode: '〒170-0013',
        location: '東京都豊島区東池袋1丁目42-14 28山京ビル5階',
        mapLink: 'https://maps.app.goo.gl/knJwBVNQCBwoC2h56',
        phoneNumber: '050-5784-3022',
      },
    },
    {
      th: '秋葉原支店',
      td: {
        zipcode: '〒110-0016',
        location: '東京都台東区台東1-1-14 D’s VARIE秋葉原ビル5階',
        mapLink: 'https://maps.app.goo.gl/o1XmkDif7n8zMXFw5',
        phoneNumber: '050-5784-3023',
      },
    },
    {
      th: '札幌支店',
      td: {
        zipcode: '〒060-0002',
        location: '北海道札幌市中央区北2条西3-1-21 札幌北2条ビル7階',
        mapLink: 'https://goo.gl/maps/vHZPENFeJ1P2',
        phoneNumber: '050-5784-3012',
      },
    },
    {
      th: '札幌コンタクトセンター',
      td: {
        zipcode: '〒060-0032',
        location: '北海道札幌市中央区北二条東2丁目1-16 キョウエイ札幌ビル5階',
        mapLink: 'https://maps.app.goo.gl/cAUHpA72TEeJ2PWJ9',
        phoneNumber: '0120-553-647',
      },
    },
    {
      th: '仙台支店',
      td: {
        zipcode: '〒980-0013',
        location: '宮城県仙台市青葉区花京院1-1-5　オークツリー花京院9F',
        mapLink: 'https://maps.app.goo.gl/3LRyPZpJkXgzhzeG9',
        phoneNumber: '050-5784-3014',
      },
    },
    {
      th: '新潟支店',
      td: {
        zipcode: '〒950-0088',
        location:
          '新潟県新潟市中央区万代1-4-33 損保ジャパン・新潟セントラルビル5階',
        mapLink: 'https://maps.app.goo.gl/dJi7RSpLjbpjgA8U9',
        phoneNumber: '050-5784-3016',
      },
    },
    {
      th: '大阪支店',
      td: {
        zipcode: '〒531-0072',
        location: '大阪府大阪市北区太融寺町5-13 東梅田パークビル9F',
        mapLink: 'https://maps.app.goo.gl/D3eJsU4zuNaaxoWMA',
        phoneNumber: '050-5784-3018',
      },
    },
    {
      th: '福岡支店',
      td: {
        zipcode: '〒810-0002',
        location: '福岡県福岡市中央区西中洲12-33 福岡大同生命ビル11F',
        mapLink: 'https://goo.gl/maps/S8iMfDf1fS7RASLcA?coh=178571&entry=tt',
        phoneNumber: '050-5784-3024',
      },
    },
    {
      th: '沖縄支店',
      td: {
        zipcode: '〒900-0032',
        location: '沖縄県那覇市松山1-1-19 JPR那覇ビル5F',
        mapLink: 'https://maps.app.goo.gl/uScJi9TqjWmAjLpb6',
        phoneNumber: '050-5784-3028',
      },
    },
  ],
}

const Base2: Base = {
  name: '株式会社ジョブス',
  thumbnail: '/company/img/base02.png',
  thumbnailClass: '',
  link: 'https://jobs-cp.jp/',
  service:
    '労働者派遣事業、有料職業紹介事業、第一種貨物利用運送事業、アウトソーシング事業',
  zipcode: '〒160-0022',
  location: '東京都新宿区新宿2-3-13 大橋ビル4階',
  tel: '03-6380-6825',
  fax: '03-3359-7691',
  tbody: [
    {
      th: '本社',
      td: {
        zipcode: '〒160-0022',
        location: '東京都新宿区新宿2-3-13 大橋ビル4F',
        mapLink: 'https://maps.app.goo.gl/TyBjWiEv18btLwF17',
        phoneNumber: '03-6380-6825',
      },
    },
    {
      th: '西船橋支店',
      td: {
        zipcode: '〒273-0032',
        location: '千葉県船橋市葛飾町2-381-4 サンモール五番館202',
        mapLink: 'https://maps.app.goo.gl/8Ykq11dGKYDGPaDq7',
        phoneNumber: '047-420-8827',
      },
    },
    {
      th: '横浜支店',
      td: {
        zipcode: '〒221-0056',
        location: '神奈川県横浜市神奈川区金港町6-14 ステートビル横浜5F',
        mapLink: 'https://maps.app.goo.gl/8Ykq11dGKYDGPaDq7',
        phoneNumber: '045-440-0251',
      },
    },
    {
      th: '大宮支店',
      td: {
        zipcode: '〒330-0845',
        location: '埼玉県さいたま市大宮区仲町2-25 松亀プレジデントビル2F',
        mapLink: 'https://maps.app.goo.gl/hqUWonDc2NnX7UpG8',
        phoneNumber: '048-658-4747',
      },
    },
    {
      th: '水戸支店',
      td: {
        zipcode: '〒310-0803',
        location: '茨城県水戸市城南2丁目1-20 井門水戸ビル5F',
        mapLink: 'https://maps.app.goo.gl/jLnmFJojooN5mFaw6',
        phoneNumber: '029-300-1500',
      },
    },
    {
      th: '札幌支店',
      td: {
        zipcode: '〒060-0002',
        location: '北海道札幌市中央区北2条西3-1-21　札幌北2条ビル7階',
        mapLink: 'https://maps.app.goo.gl/86bTdfk6AhGm1eTm9',
        phoneNumber: '011-200-2176',
      },
    },
    {
      th: '大阪支店',
      td: {
        zipcode: '〒530-0051',
        location: '大阪府大阪市北区太融寺町5-13 東梅田パークビル9F',
        mapLink: 'https://goo.gl/maps/coBfpTLrc5m',
        phoneNumber: '06-6360-6022',
      },
    },
    {
      th: '神戸営業所',
      td: {
        zipcode: '〒651-0095',
        location: '兵庫県神戸市中央区旭通2-7-8 インテリアビル3F',
        mapLink: 'https://maps.app.goo.gl/9UtViuyPidKEeMWT9',
        phoneNumber: '078-222-1512',
      },
    },
    {
      th: '福岡支店',
      td: {
        zipcode: '〒812-0011',
        location:
          '福岡県福岡市博多区博多駅前4-2-1　ザイマックス博多駅前ビル2階',
        mapLink: 'https://maps.app.goo.gl/f8fN2X4XCX2V5TfP7',
        phoneNumber: '092-292-8114',
      },
    },
    {
      th: '仙台支店',
      td: {
        zipcode: '〒980-0014',
        location: '宮城県仙台市青葉区本町1-3-9　第六広瀬ビル4階',
        mapLink: 'https://goo.gl/maps/Pj1LhgkDQaZm8o7D7',
        phoneNumber: '022-216-3611',
      },
    },
    {
      th: '広島支店',
      td: {
        zipcode: '〒730-0029',
        location: '広島県広島市中区三川町2-10　愛媛ビル・広島2F',
        mapLink: 'https://maps.app.goo.gl/2sQdRHN4FPax5tEF7',
        phoneNumber: '082-545-0520',
      },
    },
  ],
}
const Base3: Base = {
  name: '株式会社CRドットアイ',
  thumbnail: '/company/img/base03.png',
  thumbnailClass: '',
  link: 'https://crdoti.co.jp/',
  service: 'ソフトウェア開発、スマートフォンアプリ開発、労働者派遣事業、DX事業',
  zipcode: '〒110-0016',
  location: '東京都台東区台東1-1-14　D’s VARIE秋葉原ビル5階',
  tel: '03-6284-2120',
  fax: '03-3834-2240',
  tbody: [
    {
      th: '本社',
      td: {
        zipcode: '〒110-0016',
        location: '東京都台東区台東1-1-14　D’s VARIE秋葉原ビル5階',
        mapLink: 'https://maps.app.goo.gl/2JDPaiwRR1iRfpZv6',
        phoneNumber: '03-6284-2120',
      },
    },
  ],
}
const Base4: Base = {
  name: '株式会社プロテクス',
  thumbnail: '/company/img/base04.png',
  thumbnailClass: '',
  link: 'https://crprotex.co.jp/',
  service:
    '工場内製造業務、工場内物流業務、倉庫運営、他関連業務、第一種貨物利用運送事業、人材派遣事業',
  zipcode: '〒163-0437',
  location: '東京都新宿区西新宿2-1-1 新宿三井ビルディング37階',
  tel: '03-6302-0810',
  fax: '03-3345-2771',
  tbody: [
    {
      th: '本社',
      td: {
        zipcode: '〒163-0437',
        location: '東京都新宿区西新宿2-1-1 新宿三井ビルディング37F',
        mapLink: 'https://maps.app.goo.gl/1VmpAgxNtW8ftpGS9',
        phoneNumber: '03-6302-0810',
      },
    },
    {
      th: '埼玉営業所',
      td: {
        zipcode: '〒369-0307',
        location: '埼玉県児玉郡上里町嘉美1600－11',
        mapLink: 'https://maps.app.goo.gl/F3XGHN78U1ahR3bY8',
        phoneNumber: '0495-35-0271',
      },
    },
    {
      th: '浜松支店',
      td: {
        zipcode: '〒430-0935',
        location: '静岡県浜松市中央区区伝馬町312-32 MIテラス浜松3階',
        mapLink: 'https://goo.gl/maps/AKDYhiqdc2Bghmpm8',
        phoneNumber: '053-413-1101',
      },
    },
    {
      th: '静岡営業所',
      td: {
        zipcode: '〒425-0006',
        location: '静岡県焼津市関方16-1',
        mapLink: 'https://maps.app.goo.gl/6ogppkxm9nPRQaCQ8',
        phoneNumber: '054-621-3618',
      },
    },
    {
      th: '伊丹営業所',
      td: {
        zipcode: '〒664-0831',
        location: '兵庫県伊丹市北伊丹9-67',
        mapLink: 'https://maps.app.goo.gl/QUYebjiLGEoKXrSG6',
        phoneNumber: '072-782-6005',
      },
    },
    {
      th: '香川営業所',
      td: {
        zipcode: '〒769-1604',
        location: '香川県観音寺市豊浜町箕浦2518-8',
        mapLink: 'https://maps.app.goo.gl/6M5Xxn8qitPNox3M8',
        phoneNumber: '0875-56-3571',
      },
    },
  ],
}
const Base5: Base = {
  name: '株式会社パレット',
  thumbnail: '/company/img/base06.png',
  thumbnailClass: '',
  link: 'https://crpalette.co.jp/',
  service: '障がい者福祉サービス事業',
  zipcode: '〒163-0437',
  location: '東京都新宿区西新宿2-1-1 新宿三井ビルディング37階',
  tel: '03-6380-0125',
  fax: '03-5919-0364',
  tbody: [
    {
      th: '本社',
      td: {
        zipcode: '〒163-0437',
        location: '東京都新宿区西新宿2-1-1　新宿三井ビルディング37階',
        mapLink: 'https://maps.app.goo.gl/Ju5rXpQ2RmikaTMd6',
        phoneNumber: '03-6709-9620',
      },
    },
    {
      th: 'Canvas水戸',
      td: {
        zipcode: '〒310-0803',
        location: '茨城県水戸市城南2-9-12　第3プリンスビル1階',
        mapLink: 'https://goo.gl/maps/zUFisjatnwFyH52D9',
        phoneNumber: '029-302-0521',
      },
    },
    {
      th: 'Canvas土浦',
      td: {
        zipcode: '〒300-0036',
        location: '茨城県土浦市大和町9-3 ウララ3ビル 4階',
        mapLink: 'https://maps.app.goo.gl/bwcJU41RHTsyANp59',
        phoneNumber: '029-825-5530',
      },
    },
    {
      th: 'Colors水戸',
      td: {
        zipcode: '〒310-0803',
        location: '茨城県水戸市城南2-9-32　第1プリンスビル1階',
        mapLink: 'https://maps.app.goo.gl/QLPAFhXa5zM1RJ8U8',
        phoneNumber: '029-302-7721',
      },
    },
    {
      th: 'Colors武蔵境',
      td: {
        zipcode: '〒180-0022',
        location:
          '東京都武蔵野市境2-2-19　イニシャルハウス3階　<br class="hidden pc:block"/>ディーキャリア武蔵境オフィス',
        mapLink: 'https://maps.app.goo.gl/PhmU1s8aahKpuZbA9',
        phoneNumber: '0422-59-0851',
      },
    },
    {
      th: 'BPOセンター',
      td: {
        zipcode: '〒310-0803',
        location: '茨城県水戸市城南2-10-6 Gardens水戸4階',
        mapLink: 'https://maps.app.goo.gl/TK1yDjTmxVWswCPp8',
        phoneNumber: '029-302-7723',
      },
    },
  ],
}
const Base6: Base = {
  name: 'CRGインベストメント株式会社',
  thumbnail: '/company/img/base07.png',
  thumbnailClass: '',
  link: 'https://crg-ivm.co.jp/',
  service: 'M&A・投資事業',
  zipcode: '〒163-0437',
  location: '東京都新宿区西新宿2-1-1 新宿三井ビルディング37階',
  tel: '03-5989-0857',
  fax: '03-3345-2771',
  tbody: [
    {
      th: '本社',
      td: {
        zipcode: '〒163-0437',
        location: '東京都新宿区西新宿2-1-1 新宿三井ビルディング37F',
        mapLink: 'https://maps.app.goo.gl/Z6hu3RYTmA1gRe5r9',
        phoneNumber: '03-5989-0857',
      },
    },
  ],
}
const Base7: Base = {
  name: '株式会社オシエテ',
  thumbnail: '/company/img/base08.png',
  thumbnailClass: 'thumb-border',
  link: 'https://ociete.co.jp/',
  service: '法人向けオンライン通訳サービス「オシエテ」',
  zipcode: '〒163-0437',
  location: '東京都新宿区西新宿2-1-1 新宿三井ビルディング37階',
  tel: '03-6868-8786',

  tbody: [
    {
      th: '本社',
      td: {
        zipcode: '〒163-0437',
        location: '東京都新宿区西新宿2-1-1 新宿三井ビルディング37階',
        mapLink: 'https://goo.gl/maps/tXLZn2ngRUC2',
        phoneNumber: '03-6868-8786',
      },
    },
    {
      th: '営業所',
      td: {
        zipcode: '〒110-0016',
        location: '東京都台東区台東1-1-14 D’s VARIE秋葉原ビル5階',
        mapLink: 'https://maps.app.goo.gl/YaDoLtJEpfC2cVgh7',
        phoneNumber: '03-6868-8786',
      },
    },
  ],
}

const Base8: Base = {
  name: '株式会社クレイリッシュ',
  thumbnail: '/company/img/base09.png',
  thumbnailClass: 'thumb-border',
  link: 'https://901901.jp/',
  service: '事業者向けの金融事業',
  zipcode: '〒337-0051',
  location: '埼玉県さいたま市見沼区東大宮5-4-2 ロンポワンビル1階',
  tel: '048-682-2300',
  fax: '048-682-2302',
}

export { Base1, Base2, Base3, Base4, Base5, Base6, Base7, Base8 }
